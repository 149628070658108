import ReferralFilterModuleItems
  from "@/includes/logic/Modules/components/typedChipsWrappers/ReferralFilterModuleItems.vue";
import ChipValueSettingView from "@/components/ChipValueSettingView.vue";
import i18n from "@/i18n/i18n";

import { PossibleChipTypeInfo } from 'piramis-base-components/src/components/TypedChips/typedChips.types'

import { pick } from "lodash";

export const BASE_SUPER_LIST_TYPES: PossibleChipTypeInfo = {
  'Equals': {
    label: i18n.t('base_super_list_type_equals').toString(),
    prefix: 'e:',
    color: 'blue',
    view: ChipValueSettingView,
    typeKey: 'type'
  },
  'Contains': {
    isDefault: true,
    label: i18n.t('base_super_list_type_contains').toString(),
    prefix: 'c:',
    color: 'orange',
    view: ChipValueSettingView,
    typeKey: 'type'
  },
  'StartWith': {
    label: i18n.t('base_super_list_type_start_with').toString(),
    prefix: 'sw:',
    color: 'red',
    view: ChipValueSettingView,
    typeKey: 'type'
  },
  'EndWiths': {
    label: i18n.t('base_super_list_type_end_with').toString(),
    prefix: 'ew:',
    color: 'green',
    view: ChipValueSettingView,
    typeKey: 'type'
  },
  'RegExp': {
    label: i18n.t('base_super_list_type_reg_exp').toString(),
    prefix: 'rx:',
    color: 'cyan',
    view: ChipValueSettingView,
    typeKey: 'type'
  },
  'WordEquals': {
    label: i18n.t('base_super_list_type_word_equals').toString(),
    prefix: 'we:',
    color: 'purple',
    view: ChipValueSettingView,
    typeKey: 'type'
  },
}

export const BOT_COMMAND_FILTER_TYPES = pick(BASE_SUPER_LIST_TYPES, [ 'Contains', 'Equals', 'StartWith', 'WordEquals' ])
export const REPUTATION_TYPES = Object.assign(pick(BASE_SUPER_LIST_TYPES, [ 'Contains', 'Equals', 'WordEquals' ]), {
  'Fill': {
    label: i18n.t('reputation_message_super_list_type_fill').toString(),
    prefix: 'f:',
    color: 'cyan',
    view: ChipValueSettingView,
    typeKey: 'type'
  }
})

export const STICKER_PACK_FILTER_TYPES: PossibleChipTypeInfo = {
  'StickerPack': {
    isDefault: true,
    label: i18n.t('sticker_pack_super_list_type_sticker_pack').toString(),
    prefix: 'sp:',
    color: 'blue',
    view: ChipValueSettingView,
    typeKey: 'type'
  },
  'CustomEmojiPack': {
    label: i18n.t('sticker_pack_super_list_type_custom_emoji_pack').toString(),
    prefix: 'ce:',
    color: 'orange',
    view: ChipValueSettingView,
    typeKey: 'type'
  },
}

export const REFERRAL_FILTER_TYPES: PossibleChipTypeInfo = {
  'Link': {
    isDefault: true,
    label: i18n.t('referral_super_list_type_link').toString(),
    prefix: 'l:',
    color: 'blue',
    view: ReferralFilterModuleItems,
    typeKey: 'type'
  },
  'Source': {
    label: i18n.t('referral_super_list_type_source').toString(),
    prefix: 's:',
    color: 'orange',
    view: ReferralFilterModuleItems,
    typeKey: 'type'
  },
  'CodeEquals': {
    label: i18n.t('referral_super_list_type_code_equals').toString(),
    prefix: 'ce:',
    color: 'red',
    view: ReferralFilterModuleItems,
    typeKey: 'type'
  },
  'CodeStartWith': {
    label: i18n.t('referral_super_list_type_code_start_with').toString(),
    prefix: 'csw:',
    color: 'green',
    view: ReferralFilterModuleItems,
    typeKey: 'type'
  },
  'CodeContains': {
    label: i18n.t('referral_super_list_type_code_contains').toString(),
    prefix: 'c:',
    color: 'cyan',
    view: ReferralFilterModuleItems,
    typeKey: 'type'
  },
}
